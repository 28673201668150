<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseSelect
		:value="value"
		:placeholder="$t('placeholder.country')"
		:label="$t('label.country')"
		:options="getCountries()"
		:validation-rules="{ required: required }"
		:validation-messages="{ required: $t('error.countryRequired') }"
		:automation-id="getAutomationId('country')"
		@change="onChange"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
/**
 * This component is responsible to pick a country
 */
import BaseSelect from '@/components/common/base/BaseSelect.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import IdMixin from '@/mixins/id';
extend('required', required);

// @vue/component
@Component({
	name: 'CountrySelect',
	components: {
		BaseSelect
	},
	mixins: [IdMixin],
	/**
	 * this defines how to v-model is binded.
	 */
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: '',
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class CountrySelect extends Vue {
	/**
	 * Returns a list of countries
	 * @returns {array}
	 */
	getCountries() {
		return [
			{ value: 'CAN', text: this.$t('country.can') },
			{ value: 'US', text: this.$t('country.usa') },
			{ value: 'OTHER', text: this.$t('country.other') }
		];
	}
	/**
	 * handle onchange event.
	 * @param {string} value The value
	 */
	onChange(value) {
		this.$emit('change', value);
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
{
	"en": {
		"label": {
			"country": "Country"
		},
		"error": {
			"countryRequired": "Please provide your country."
		},
		"placeholder": {
			"country": "Select a country"
		},
		"country": {
			"can": "Canada",
			"usa": "United States",
			"other": "Other"
		}
	},
	"fr": {
		"label": {
			"country": "Pays"
		},
		"error": {
			"countryRequired": "Veuillez indiquer votre pays."
		},
		"placeholder": {
			"country": "Sélectionnez un pays"
		},
		"country": {
			"can": "Canada",
			"usa": "Étas-Unis",
			"other": "Autre"
		}
	}
}
</i18n>
