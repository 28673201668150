<template>
	<BaseSelect
		:value="value"
		:placeholder="$t('placeholder.state')"
		:label="$t('label.state')"
		:options="states"
		:validation-rules="{ required: required }"
		:validation-messages="{ required: requireStateError }"
		:automation-id="getAutomationId('state')"
		@change="onChange"
	/>
</template>

<script>
/**
 * This component renders the State Select dropdown.
 */
import BaseSelect from '@/components/common/base/BaseSelect.vue';
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import Component from 'vue-class-component';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
// @vue/component
@Component({
	name: 'StateSelect',
	components: {
		BaseSelect
	},
	mixins: [IdMixin],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		},
		/**
		 * This prop is passed in as true or false to determine if this component is required or not.
		 */
		required: {
			type: Boolean,
			default: false
		},
		/**
		 * If the required prop is true it will display this props value if no value was selected.
		 */
		requiredErrorMsg: {
			type: String,
			default: ''
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class StateSelect extends Vue {
	/**
	 * Returns list of states
	 * @returns {array}
	 */
	get states() {
		return [
			{ value: 'AK', text: this.$t('state.ak') },
			{ value: 'AL', text: this.$t('state.al') },
			{ value: 'AR', text: this.$t('state.ar') },
			{ value: 'AZ', text: this.$t('state.az') },
			{ value: 'CA', text: this.$t('state.ca') },
			{ value: 'CO', text: this.$t('state.co') },
			{ value: 'CT', text: this.$t('state.ct') },
			{ value: 'DC', text: this.$t('state.dc') },
			{ value: 'DE', text: this.$t('state.de') },
			{ value: 'FL', text: this.$t('state.fl') },
			{ value: 'GA', text: this.$t('state.ga') },
			{ value: 'HI', text: this.$t('state.hi') },
			{ value: 'IA', text: this.$t('state.ia') },
			{ value: 'ID', text: this.$t('state.id') },
			{ value: 'IL', text: this.$t('state.il') },
			{ value: 'IN', text: this.$t('state.in') },
			{ value: 'KS', text: this.$t('state.ks') },
			{ value: 'KY', text: this.$t('state.ky') },
			{ value: 'LA', text: this.$t('state.la') },
			{ value: 'MA', text: this.$t('state.ma') },
			{ value: 'MD', text: this.$t('state.md') },
			{ value: 'ME', text: this.$t('state.me') },
			{ value: 'MI', text: this.$t('state.mi') },
			{ value: 'MN', text: this.$t('state.mn') },
			{ value: 'MO', text: this.$t('state.mo') },
			{ value: 'MS', text: this.$t('state.ms') },
			{ value: 'MT', text: this.$t('state.mt') },
			{ value: 'NC', text: this.$t('state.nc') },
			{ value: 'ND', text: this.$t('state.nd') },
			{ value: 'NE', text: this.$t('state.ne') },
			{ value: 'NH', text: this.$t('state.nh') },
			{ value: 'NJ', text: this.$t('state.nj') },
			{ value: 'NM', text: this.$t('state.nm') },
			{ value: 'NV', text: this.$t('state.nv') },
			{ value: 'NY', text: this.$t('state.ny') },
			{ value: 'OH', text: this.$t('state.oh') },
			{ value: 'OK', text: this.$t('state.ok') },
			{ value: 'OR', text: this.$t('state.or') },
			{ value: 'PA', text: this.$t('state.pa') },
			{ value: 'RI', text: this.$t('state.ri') },
			{ value: 'SC', text: this.$t('state.sc') },
			{ value: 'SD', text: this.$t('state.sd') },
			{ value: 'TN', text: this.$t('state.tn') },
			{ value: 'TK', text: this.$t('state.tk') },
			{ value: 'UT', text: this.$t('state.ut') },
			{ value: 'VT', text: this.$t('state.vt') },
			{ value: 'WA', text: this.$t('state.wa') },
			{ value: 'WI', text: this.$t('state.wi') },
			{ value: 'WV', text: this.$t('state.wv') },
			{ value: 'WY', text: this.$t('state.wy') },
			{ value: 'VA', text: this.$t('state.va') }
		];
	}
	/**
	 * Handle onchange event
	 * @param {string} value The value.
	 */
	onChange(value) {
		this.$emit('change', value);
	}
	/**
	 * returns a custom error message for required if provided else will return the default message
	 */
	get requireStateError() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.required');
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
  "en": {
    "label": {
      "state": "State"
    },
    "placeholder": {
      "state": "Select a state"
    },
    "error": {
      "required": "Please provide your state."
    },
    "state": {
      "ak": "Alaska",
      "al": "Alabama",
      "ar": "Arkansas",
      "az": "Arizona",
      "ca": "California",
      "co": "Colorado",
      "ct": "Connecticut",
      "dc": "District of Columbia",
      "de": "Delaware",
      "fl": "Florida",
      "ga": "Georgia",
      "hi": "Hawaii",
      "ia": "Iowa",
      "id": "Idaho",
      "il": "Illinois",
      "in": "Indiana",
      "ks": "Kansas",
      "ky": "Kentucky",
      "la": "Louisiana",
      "ma": "Massachusetts",
      "md": "Maryland",
      "me": "Maine",
      "mi": "Michigan",
      "mn": "Minnesota",
      "mo": "Missouri",
      "ms": "Mississippi",
      "mt": "Montana",
      "nc": "North Carolina",
      "nd": "North Dakota",
      "ne": "Nebraska",
      "nh": "New Hampshire",
      "nj": "New Jersey",
      "nm": "New Mexico",
      "nv": "Nevada",
      "ny": "New York",
      "oh": "Ohio",
      "ok": "Oklahoma",
      "or": "Oregon",
      "pa": "Pennsylvania",
      "ri": "Rhode Island",
      "sc": "South Carolina",
      "sd": "South Dakota",
      "tn": "Tennessee",
      "tk": "Texas",
      "ut": "Utah",
      "vt": "Vermont",
      "wa": "Washington",
      "wi": "Wisconsin",
      "wv": "West Virginia",
      "wy": "Wyoming",
      "va": "Virginia"
    }
  },
  "fr": {
    "label": {
      "state": "État"
    },
    "placeholder": {
      "state": "Sélectionner un état"
    },
    "error": {
      "required": "Veuillez indiquer votre État."
    },
    "state": {
      "ak": "Alaska",
      "al": "Alabama",
      "ar": "Arkansas",
      "az": "Arizona",
      "ca": "California",
      "co": "Colorado",
      "ct": "Connecticut",
      "dc": "District of Columbia",
      "de": "Delaware",
      "fl": "Florida",
      "ga": "Georgia",
      "hi": "Hawaii",
      "ia": "Iowa",
      "id": "Idaho",
      "il": "Illinois",
      "in": "Indiana",
      "ks": "Kansas",
      "ky": "Kentucky",
      "la": "Louisiana",
      "ma": "Massachusetts",
      "md": "Maryland",
      "me": "Maine",
      "mi": "Michigan",
      "mn": "Minnesota",
      "mo": "Missouri",
      "ms": "Mississippi",
      "mt": "Montana",
      "nc": "North Carolina",
      "nd": "North Dakota",
      "ne": "Nebraska",
      "nh": "New Hampshire",
      "nj": "New Jersey",
      "nm": "New Mexico",
      "nv": "Nevada",
      "ny": "New York",
      "oh": "Ohio",
      "ok": "Oklahoma",
      "or": "Oregon",
      "pa": "Pennsylvania",
      "ri": "Rhode Island",
      "sc": "South Carolina",
      "sd": "South Dakota",
      "tn": "Tennessee",
      "tk": "Texas",
      "ut": "Utah",
      "vt": "Vermont",
      "wa": "Washington",
      "wi": "Wisconsin",
      "wv": "West Virginia",
      "wy": "Wyoming",
      "va": "Virginia"
    }
  }
}
</i18n>
