<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseInput
		v-mask="mask"
		:optional-label="optionalLabel"
		v-bind="$attrs"
		:automation-id="getAutomationId('masked')"
		@input="onInput"
		@change="onChange"
		@blur="onBlur"
	>
		<template #labelButton>
			<slot name="infoButton"></slot>
		</template>
	</BaseInput>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { mask } from 'vue-the-mask';
import BaseInput from '@/components/common/base/BaseInput.vue';

/**
 * Component renders a masked input.
 */
// @vue/component
@Component({
	name: 'BaseMaskedInput',
	components: {
		BaseInput
	},
	directives: { mask },
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/**
		 * This prop controls the mask
		 */
		mask: {
			required: true,
			validator: (prop) => typeof prop === 'string' || prop === null || prop === undefined
		},
		optionalLabel: {
			type: String,
			default: ''
		},
		/**
		 * This prop is used for test automation Id's
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class VMaskedInput extends Vue {
	/**
	 * Handle input event.
	 *
	 * @param value {String} Checked value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}

	onBlur(evt) {
		this.$emit('blur', evt);
	}
	onChange(value) {
		this.$emit('change', value);
	}
}
</script>
