<template>
	<BaseCard automation-id="changeAddress" :title="$t('title')" title-tag="h2">
		<BaseDisplayGroup automation-id="name" :label="$t('label.name')">
			{{ name }}
		</BaseDisplayGroup>

		<BaseForm automation-id="changeAddressForm" @submit="onSave">
			<AddressInput
				class="col-lg-6"
				:label="$t('label.streetAddressLine1')"
				:placeholder="$t('placeholder.streetAddressLine1')"
				:value="localValue.addressLine1"
				:automation-id="getAutomationId('change-address-line1')"
				@input="updateAddressLine1"
			/>
			<AddressInput
				class="col-lg-6"
				:value="localValue.addressLine2"
				:automation-id="getAutomationId('change-address-line2')"
				@input="updateAddressLine2"
			/>
			<AddressInput
				class="col-lg-6"
				:value="localValue.addressLine3"
				:automation-id="getAutomationId('change-address-line3')"
				@input="updateAddressLine3"
			/>
			<CityInput
				v-if="localValue.country !== other"
				class="col-lg-5"
				:label="$t('label.city')"
				:value="localValue.city"
				:is-required="true"
				:automation-id="getAutomationId('changeAddressInfo')"
				@input="updateCity"
			/>
			<ProvinceSelect
				v-if="localValue.country === canada"
				class="col-lg-4"
				:value="localValue.province"
				:required="true"
				:automation-id="getAutomationId('changeAddressInfo')"
				@change="updateProvince"
			/>
			<StateSelect
				v-if="localValue.country === unitedStates"
				class="col-lg-4"
				:value="localValue.state"
				:required="true"
				:automation-id="getAutomationId('changeAddressInfo')"
				@change="updateState"
			/>
			<CountrySelect
				class="col-lg-4"
				:value="localValue.country"
				:automation-id="getAutomationId('changeAddressInfo')"
				@change="updateCountry"
			/>
			<PostalCode
				v-if="localValue.country === canada"
				class="col-lg-2"
				:value="localValue.postalCode"
				:automation-id="getAutomationId('postalCode')"
				@blur="updatePostalCode"
			/>
			<ZipCodeInput
				v-if="localValue.country === unitedStates"
				class="col-lg-2"
				:value="localValue.zipCode"
				:automation-id="getAutomationId('zipCode')"
				@blur="updateZipCode"
			/>
			<PhoneNumberInput
				class="col-lg-3"
				:value="localValue.phone"
				:label="$t('label.phone')"
				:automation-id="getAutomationId('changeAddressInfo')"
				@blur="updatePhone"
			/>
			<BaseButton
				ref="btnSave"
				:label="$t('button.save')"
				variant="primary"
				type="submit"
				:automation-id="getAutomationId('changeAddressInfoSubmit')"
			/>
			<BaseButton
				ref="btnCancel"
				:label="$t('button.cancel')"
				variant="link"
				:automation-id="getAutomationId('changeAddressInfoCancel')"
				@click="onCancel()"
			/>
		</BaseForm>
	</BaseCard>
</template>
<script>
import BaseForm from '@/components/common/base/BaseForm';
import AddressInput from '@/components/common/AddressInput';
import CityInput from '@/components/common/CityInput';
import ProvinceSelect from '@/components/common/ProvinceSelect';
import StateSelect from '@/components/common/StateSelect';
import CountrySelect from '@/components/common/CountrySelect';
import PostalCode from '@/components/common/PostalCode';
import ZipCodeInput from '@/components/common/ZipCodeInput';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id';
import { PERSONAL_INFORMATION } from '@/constants/Routes';
import * as Countries from '@/constants/Country';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';

export default {
	name: 'ChangeAddress',
	components: {
		BaseForm,
		AddressInput,
		BaseButton,
		CityInput,
		ProvinceSelect,
		StateSelect,
		CountrySelect,
		PostalCode,
		ZipCodeInput,
		PhoneNumberInput,
		BaseCard,
		BaseDisplayGroup
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		name: {
			type: String,
			default: ''
		}
	},
	emits: ['save', 'input'],
	computed: {
		canada() {
			return Countries.CANADA;
		},
		unitedStates() {
			return Countries.UNITED_STATES;
		},

		other() {
			return Countries.OTHER;
		},

		localValue() {
			return this.value ? this.value : {};
		}
	},
	methods: {
		updateAddressLine1: function (addressLine1) {
			this.$emit('input', { ...this.value, addressLine1 });
		},

		updateAddressLine2: function (addressLine2) {
			this.$emit('input', { ...this.value, addressLine2 });
		},

		updateAddressLine3: function (addressLine3) {
			this.$emit('input', { ...this.value, addressLine3 });
		},

		updateCity: function (city) {
			this.$emit('input', { ...this.value, city });
		},

		updateProvince: function (province) {
			this.$emit('input', { ...this.value, province });
		},
		updateState: function (state) {
			this.$emit('input', { ...this.value, state });
		},
		updateCountry: function (country) {
			if (country === Countries.OTHER) this.localValue.city = '';
			this.$emit('input', { ...this.value, country });
		},

		updatePostalCode: function (postalCode) {
			this.$emit('input', { ...this.value, postalCode });
		},

		updateZipCode: function (zipCode) {
			this.$emit('input', { ...this.value, zipCode });
		},

		updatePhone: function (phone) {
			this.$emit('input', { ...this.value, phone });
		},

		onSave() {
			this.$emit('save');
		},

		onCancel() {
			this.$router.push({ name: PERSONAL_INFORMATION });
		}
	}
};
</script>

<style lang="scss" scoped>
[class*='col-'] {
	padding-left: 0;
	padding-right: 0;
}
</style>

<i18n>
{
  "en": {
    "title": "Update your address and contact information",
    "label": {
      "name": "Name",
      "streetAddressLine1": "Address",
      "city": "City",
      "phone": "Phone"
    },
    "placeholder": {
      "streetAddressLine1": "Enter the first line of your address"
    },
    "button": {
      "save": "Save",
      "cancel": "Cancel"
    }
  },
  "fr": {
    "title": "Mettre à jour votre adresse et vos coordonnées",
    "label": {
      "name": "Nom",
      "streetAddressLine1": "Adresse",
      "city": "Ville",
      "phone": "Téléphone"
    },
    "placeholder": {
      "streetAddressLine1": "Entrez votre première ligne d'addresse"
    },
    "button": {
      "save": "Enregistrer",
      "cancel": "Annuler"
    }
  }
}
</i18n>
