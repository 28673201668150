<template>
	<div>
		<BaseMaskedInput
			v-bind="$attrs"
			:value="maskedValue"
			:label="label"
			:optional-label="optionalPhoneLabel"
			:mask="mask"
			:placeholder="mask"
			:validation-rules="{ required: isRequired, isValidPhone: true }"
			:validation-messages="{ required: requiredErrorMsgComp, isValidPhone: invalidErrorMsgComp }"
			:automation-id="getAutomationId('phonenumber')"
			@input="onInput"
			@blur="onBlur"
		/>
	</div>
</template>

<script>
/**
 * This component is used to render a phone number input.
 * It masks the value entered.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);

extend('isValidPhone', {
	validate: (value) => {
		let regex =
			/^[(]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[-\s]{0,1}[-\s]{0,1}[0-9]{4}$/;
		if (value === '') return true;
		return regex.test(value);
	}
});
// @vue/component
@Component({
	name: 'PhoneNumberInput',
	components: {
		BaseMaskedInput
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: ''
		},
		/**
		 * This prop is used to display an optional label beside the main label.
		 * It is used to be slighty lighter in color
		 */
		optionalPhoneLabel: {
			type: String,
			default: null
		},
		/**
		 * This prop is used to determine what the mask of the input field will be.
		 */
		mask: {
			default: '( ### ) ### - ####',
			type: String
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the phone number is required.
		 */
		requiredErrorMsg: {
			type: String,
			default: ''
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the length of the value entered is incorrect.
		 */
		isValidErrorMsg: {
			type: String,
			default: ''
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class PhoneNumberInput extends Vue {
	savedMaskedValue = null;

	/**
	 * retrives the masked value
	 */
	get maskedValue() {
		this.savedMaskedValue = this.value;
		return this.value;
	}

	/**
	 * on blur will use the saved masked value and emit it using the 'blur' event.
	 */
	onBlur() {
		const phoneNumber = this.savedMaskedValue;
		this.$emit('blur', phoneNumber);
	}

	/**
	 * These computed props check if an error message was passed in,
	 * if it wasn't then it used a default error message.
	 */
	get requiredErrorMsgComp() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.required');
	}
	get invalidErrorMsgComp() {
		return this.isValidErrorMsg ? this.isValidErrorMsg : this.$t('error.invalid');
	}

	/**
	 * Handle input event
	 * @param maskedValue The masked value.
	 */
	onInput(maskedValue) {
		this.savedMaskedValue = maskedValue;
		this.$emit('input', maskedValue);
	}
}
</script>
<i18n>
{
  "en": {
    "error": {
      "required": "Please enter a phone number.",
      "invalid": "Please enter a 10 digit phone number."
    }
  },
  "fr": {
    "error": {
      "required": "Veuillez entrer un numéro de téléphone.",
      "invalid": "Veuillez entrer un numéro de téléphone à 10 chiffres."
    }
  }
}
</i18n>
