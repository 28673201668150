<template>
	<BaseMaskedInput
		v-bind="$attrs"
		:value="maskedValue"
		:label="$t('label.zipCode')"
		:mask="mask"
		:validation-rules="{ required: true, multipleLengths: true }"
		:validation-messages="{ required: requiredErrorMsgComp, multipleLengths: lengthErrorMsgComp }"
		:automation-id="getAutomationId('zipcode')"
		@input="onInput"
		@blur="onBlur"
	/>
</template>

<script>
/**
 * This component renders the Zip code input field.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput.vue';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('multipleLengths', {
	validate: (value) => {
		if (value != null) {
			const stringLength = value.length;
			if (stringLength === 5 || stringLength === 10) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	}
});
// @vue/component
@Component({
	name: 'ZipCodeInput',
	components: {
		BaseMaskedInput
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/**
		 * value is the value that will be used by
		 * this component to populate the input fields.
		 */
		value: {
			type: String,
			default: ''
		},
		/**
		 * This prop is used to determine what the mask of the input field will be.
		 */
		mask: {
			default: '#####-####',
			type: String
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the Zip code is required.
		 */
		requiredErrorMsg: {
			type: String,
			default: null,
			required: false
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the length of the value entered is incorrect.
		 */
		lengthErrorMsg: {
			type: String,
			default: null,
			required: false
		},
		/**
		 * Test automation id is used by the test automation Id
		 * suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	},
	computed: {}
})
export default class ZipCodeInput extends Vue {
	savedMaskedValue = null;

	/**
	 * These computed props check if an error message was passed in,
	 * if it wasn't then it used a default error message.
	 */
	get requiredErrorMsgComp() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.required');
	}
	get lengthErrorMsgComp() {
		return this.lengthErrorMsg ? this.lengthErrorMsg : this.$t('error.length');
	}

	/**
	 * Returns the mask value.
	 */
	get maskedValue() {
		this.savedMaskedValue = this.value;
		return this.value;
	}

	/**
	 * Handle blur event.
	 */
	onBlur() {
		const zipCode = this.savedMaskedValue;
		this.$emit('blur', zipCode);
	}

	/**
	 * Handle input event
	 * @param maskedValue The masked value.
	 */
	onInput(maskedValue) {
		this.savedMaskedValue = maskedValue;
	}
}
</script>

<i18n>
{
    "en": {
		"label": {
			"zipCode": "Zip code"
		},
		"error": {
			"required": "Zip code is required.",
			"length": "Please enter valid Zip code."
		}
   },
  "fr": {
   "label": {
        "zipCode": "Code ZIP"
	},
	"error": {
		"required": "Un code ZIP est requis.",
		"length": "Veuillez entrer un code ZIP valide."
		
    }
  }
}
</i18n>
