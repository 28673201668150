<template>
	<BaseInput
		:label="label"
		:value="value"
		:max-length="40"
		:validation-rules="{ required: isRequired, alphaCharacters: true }"
		:validation-messages="{
			required: requiredErrorMessage(),
			alphaCharacters: $t('error.alphaNum')
		}"
		:automation-id="getAutomationId('country')"
		@input="updateCity"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseInput from '@/components/common/base/BaseInput';
import IdMixin from '@/mixins/id';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('alphaCharacters', {
	validate: (value) => {
		const regex = /^[0-9A-Za-zàâçéèêëîïôûùüÿñæœ¿'.\s\d-]+$/i;
		return regex.test(value);
	},
	message: '{_field_} input cannot have special characters.'
});

// @vue/component
@Component({
	name: 'CityInput',
	components: {
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		label: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			required: true
		},
		fieldRequiredErrorMessage: {
			type: String,
			default: ''
		}
	}
})
export default class CityInput extends Vue {
	updateCity(city) {
		this.$emit('input', city);
	}
	requiredErrorMessage() {
		return this.fieldRequiredErrorMessage
			? this.fieldRequiredErrorMessage
			: this.$t('error.required');
	}
}
</script>

<i18n>
{
	"en": {
		"error": {
			"required": "Please provide your city/town.",
			"alphaNum": "Oops, it seems you've entered an invalid character. Please review and try again."
		}
	},
	"fr": {
		"error": {
			"required": "Veuillez indiquer votre ville.",
			"alphaNum": "Oups! Il semble que vous ayez entré un caractère invalide. Veuillez vérifier et essayer de nouveau."
		}
	}
}
</i18n>
