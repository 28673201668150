<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template data-tracking-id="address-input">
	<BaseInput
		ref="addressInput"
		:value="value"
		:placeholder="placeholder"
		:max-length="80"
		:automation-id="getAutomationId('address')"
		:validation-rules="{ required: isRequired, alphaNumCharacters: true }"
		:validation-messages="{
			required: $t('error.addressEmpty'),
			alphaNumCharacters: $t('error.malformedAddress')
		}"
		:label="label"
		@input="updateAddress"
		@keyup="onKeyUp"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is renders an email input field
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseInput from '@/components/common/base/BaseInput';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('alphaNumCharacters', {
	validate: (value) => {
		const regex = /^[A-Za-zàâçéèêëîïôûùüÿñæœ'.\s\d-]+$/i;
		if (value === '') return true;
		return regex.test(value);
	},
	message: '{_field_} input cannot have special characters.'
});

// @vue/component
@Component({
	name: 'AddressInput',
	components: {
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		isRequired: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop is used to determine what to display above the email input field.
		 */
		label: {
			type: String,
			default: ''
		},
		/**
		 * this prop is used to display contextual text inside the input
		 */
		placeholder: {
			type: String,
			default: ''
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		}
	}
})
export default class AddressInput extends Vue {
	/**
	 * This function emits to the parent component the value entered into the address field.
	 */
	updateAddress(address) {
		this.$emit('input', address);
	}
	/**
	 * This function is used to force a character limit in the address input field.
	 * The maxlength is enough for most browsers, but on android devices it needs this function.
	 */
	onKeyUp(inputId) {
		var input = document.getElementById(inputId);
		const max = 80;
		if (input.value !== null && input.value.length >= max) {
			input.value = input.value.substring(0, max);
		}
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
  {
    "en": {
      "error": {
        "addressEmpty": "Please provide your street address.",
			  "malformedAddress": "Oops, it seems you've entered an invalid character. Please review and try again."
	    }
	  },
	  "fr": {
	    "error": {
			  "addressEmpty": "Veuillez indiquer votre adresse postale.",
			  "malformedAddress": "Oups! Il semble que vous ayez entré un caractère invalide. Veuillez vérifier et essayer de nouveau."
	    }
	  }
	}
</i18n>
