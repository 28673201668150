<template>
	<ManageYourAccountTemplate v-if="!store.state.loading" :page-title="t('title')">
		<ChangeAddress
			:value="userInfo"
			:name="userFullName"
			automation-id="changeAddress"
			@input="updateAddress"
			@save="saveAddress"
		/>
	</ManageYourAccountTemplate>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import store from '@/store';
import router from '@/router/index';
// constants
import { DASHBOARD, MANAGE_YOUR_ACCOUNT, PERSONAL_INFORMATION } from '@/constants/Routes';
import * as Countries from '@/constants/Country';
// models
import ContactInfo from '@/models/ContactInfo';
import MemberCardInfo from '@/models/MemberCardInfo';
// pages
import ManageYourAccountTemplate from '@/pages/manage-your-account/ManageYourAccountTemplate.vue';
// components
import ChangeAddress from '@/components/manage-your-account/personal-information/ChangeAddress.vue';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;

// refs
const userInfo = ref(null);
const member = ref(null);

// computed
const userFullName = computed(() => {
	return member.value ? `${member.value.firstName} ${member.value.lastName}` : '';
});

// methods
function updateAddress(user) {
	userInfo.value = user;
}

async function saveAddress() {
	let usertoSave = {};
	usertoSave.country = userInfo.value.country;
	usertoSave.addressType = userInfo.value.addressType;
	usertoSave.city = userInfo.value.city;
	usertoSave.addressLine1 = userInfo.value.addressLine1;
	usertoSave.addressLine2 = userInfo.value.addressLine2 ? userInfo.value.addressLine2 : '';
	usertoSave.addressLine3 = userInfo.value.addressLine3 ? userInfo.value.addressLine3 : '';
	if (userInfo.value.country === Countries.CANADA) {
		usertoSave.province = userInfo.value.province;
		usertoSave.postalCode = userInfo.value.postalCode;
		usertoSave.state = '';
		usertoSave.zipCode = '';
	} else if (userInfo.value.country === Countries.UNITED_STATES) {
		usertoSave.state = userInfo.value.state;
		usertoSave.zipCode = userInfo.value.zipCode;
		usertoSave.province = '';
		usertoSave.postalCode = '';
	} else {
		usertoSave.city = '';
		usertoSave.state = '';
		usertoSave.zipCode = '';
		usertoSave.province = '';
		usertoSave.postalCode = '';
	}
	store.dispatch('updateLoading', true);
	let response = await ContactInfo.updateUserInfo(
		sessionStorage.getItem('email'),
		sessionStorage.getItem('apiToken'),
		root.$i18n.locale,
		usertoSave,
		userInfo.value.phone.replace(/\D/g, '')
	);
	if (response.status <= 204) {
		router.push({ name: PERSONAL_INFORMATION, params: { hasSucceeded: true } });
	} else {
		store.dispatch('updateLoading', false);
		store.dispatch('error', {
			name: 'change-password-error',
			title: t('error.wentWrongHeader'),
			err: t('error.wentWrong')
		});
	}
}

// lifecycle methods
onMounted(async () => {
	store.dispatch('updateLoading', true);

	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');

	const results = await Promise.all([
		ContactInfo.getUserInfo(email, token, root.$i18n.locale),
		MemberCardInfo.getMemberDetail(email, token, root.$i18n.locale)
	]);
	userInfo.value = results[0];
	member.value = results[1];

	useBreadcrumbs(
		[
			{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.manageYourAccount', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
			{ text: t('breadcrumb.personalInformation', 'en') }
		],
		[
			{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.manageYourAccount', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
			{ text: t('breadcrumb.personalInformation', 'fr') }
		]
	);

	store.dispatch('updateLoading', false);
});
</script>

<i18n lang="json">
{
	"en": {
		"breadcrumb": {
			"home": "Home",
			"manageYourAccount": "Manage your account",
			"personalInformation": "Personal Information"
		},
		"title": "Personal Information",
		"error": {
			"wentWrongHeader": "Something went wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
	},
	"fr": {
		"breadcrumb": {
			"home": "Accueil",
			"manageYourAccount": "Gérer votre compte",
			"personalInformation": "Renseignements personnels"
		},
		"title": "Renseignements personnels",
		"error": {
			"wentWrongHeader": "Une erreur s'est produite",
			"wentWrong": "Nous sommes désolés, une erreur s'est produite. Veuillez réessayer plus tard."
		}
	}
}
</i18n>
